import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpMessagesService {

  public messages: { [key: string]: string } = {
        idPlanta: "Selecciona la planta en la que deseas crear el Cliente, según el punto más próximo de Distribución [Bogotá, Cali, Medellín].",
        idCliente: "Ingresa el número de Identificación del cliente.",
        idClienteShipTo: "Ingresa el número de Identificación del cliente.",
        tipoDocumento: "Selecciona tipo Documento del Cliente [Cédula Ciudadanía, Cédula Extranjería, NIT, Pasaporte etc.].",
        personaJuridica: "Selecciona la naturaleza del Cliente [Jurídico, Natural].",
        primerNombre: "Ingresa el primer nombre del cliente.",
        otrosNombres: "Ingresa los segundos nombres del cliente.",
        primerApellido: "Ingresa el primer apellido del cliente.",
        segundoApellido: "Ingresa los Segundos apellidos del Cliente.",
        razonSocial: "Ingresa el nombre de la Razón Social o Empresa del Cliente.",
        Nombre: "Ingresa el primer nombre del Ship To.",
        pais: "Ingresa el país de ubicación del Cliente.",
        paisShipTo: "Ingresa el país de ubicación del Cliente.",
        departamento: "Ingresa el departamento de ubicación del Cliente.  Solo aplica para Colombia.",
        departamentoShipTo: "Ingresa el departamento de ubicación del Cliente.  Solo aplica para Colombia.",
        municipio: "Ingresa el municipio de ubicación del Cliente.  Solo aplica para Colombia.",
        municipioShipTo: "Ingresa el municipio de ubicación del Cliente.  Solo aplica para Colombia.",
        direccion: "Ingresa la Dirección que indica el Cliente y/o aparece en RUT.",
        infoAdicional: "Ingrese información complementaria de la ubicación del cliente.",
        barrio: "Ingrese el Barrio que indica el cliente y/o aparecen en RUT.",
        telefono: "Ingrese el número celular o telefónico personal del cliente.",
        email: "Ingrese el correo electrónico indicado por el cliente para su Facturación Electrónica.",
        principal: "Campo informativo de aceptación de dirreción principal.",
        asesor: "Campo informativo de fuerza de trabajo asignada al cliente.",
        listaPrecios: "Campo informativo del código de lista de precio asignado al Cliente.",
        grupo: "Campo Informativo del grupo con el cual queda asociado el cliente.",
        comun: "Seleccione si el cliente es de regimen común",
        envio2: "Selecciona la dirección de envió que el cliente desea llegue su Orden de Pedido.",
        fechaOrden: "Campo Automático de Fecha de elaboración de la Orden.",
        fechaNecesidad: "Ingresa la fecha en la que el Cliente desea recibir su orden de pedido.",
        descuento: "Ingresa el % de Descuento aplicable a la orden si se está manejando alguna campaña promocional.",
        listaPreciosOrden: "Campo informativo del nombre de lista de precio asignado al Cliente con el que se esta haciendo la Orden de Pedido.",
        comentarios: "Ingresa información complementaria a la Orden del Pedido para su consideración en alistamiento, despacho, entrega ETC.",
        termino: "Campo informativo de los términos de negociación con este cliente.",
        vendedor: "Campo informativo de fuerza de trabajo asignada al cliente.",
        medico: "Selecciona el médico Prescriptor del Paciente/Cliente que está realizando la Orden de Pedido.",
        formaPagos: "Selecciona el método de pago con el cual el cliente cancelara la orden de pedido.",
        franjaHoraria: "Selecciona la franja horaria en la que el cliente desea recibir su orden de pedido.",
        zona: "Selecciona la zona de su pedido.",
        canal: "Selecciona el tipo de canal por el cual ingreso la solicitud de Orden de Pedido por parte del cliente.",
        productos: "Sesión para seleccionar las diferentes referencias de producto que el Cliente desea Adquirir en su orden de Pedido.",
        miscelaneo: "Sesión para seleccionar los Cargos Misceláneos adicionales a la Orden [Costos Envió Orden de Pedido.]."   
  };

  constructor() { }

  getMessage(key: string): string {
    return this.messages[key] || "Mensaje no encontrado.";
  }
}
